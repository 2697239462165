








.post-code-question {
    font-size: 14pt;
    padding-left: 40px;
    padding-right: 40px;
}

.post-code-form {
    margin-top: 16px;
}

.post-code-text-field {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 12pt;
    font-weight: 600;
    width: 200px;
    height: 36px;
    text-align: center;
    border-radius: 16px;
    border-style: dashed;
    border-width: 1px;
    box-shadow: 2px 2px 1px #FF6E59;
}

.post-code-text-field:focus {
    border-color: black;
    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 2px 1px #FF6E59;
    outline: none;
}

.post-code-sorry {
    margin-top: 16px;
    font-size: 14pt;
    font-weight: 500;
}

.post-code-success {
    margin-top: 16px;
    font-size: 14pt;
    font-weight: 500;
}

.pop-up-response-div {
    animation: expand 0.3s;
}

@keyframes expand {
    0% { transform: translateY(-10px); }
    100% { transform: none; }
}

.post-code-sorry-button {
    margin-top: 16px;
    font-size: 18px;
    width: 250px;
    height: 50px;
    margin-right: 0;
    margin-left: auto;
    background-color: #000;
    border-radius: 22px;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.post-code-success-button {
    margin-top: 16px;
    font-size: 18px;
    width: 270px;
    height: 50px;
    margin-right: 0;
    margin-left: auto;
    background-color: #000;
    border-radius: 26px;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.post-code-success-button:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.post-code-icon {
    padding-right: 6px;
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-post-code {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
    }

    .post-code-question {
        font-size: 12pt;
        text-align: left;
        padding-left: 20px;
        padding-right: 80px;
    }

    .post-code-sorry, .post-code-success {
        font-size: 11pt;
        padding-left: 6px;
        padding-right: 6px;
        font-weight: 500;
    }

    .post-code-sorry-button {
        font-size: 16px;
        width: 220px;
    }

    .post-code-success-button {
        font-size: 16px;
        width: 270px;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .modal-content-post-code {
        width: 91%;
    }

    .post-code-question {
        font-size: 11pt;
        text-align: left;
        padding-left: 20px;
        padding-right: 80px;
    }

    .post-code-sorry {
        font-size: 10pt;
    }

    .post-code-sorry-button {
        font-size: 15px;
        width: 200px;
    }

    .post-code-success-button {
        font-size: 15px;
        width: 260px;
    }
}