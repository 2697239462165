@keyframes comeFromLeft {
    0% { transform: translateX(-500px); }
    100% { transform: none; }
}

@keyframes comeFromRight {
    0% { transform: translateX(500px); }
    100% { transform: none; }
}

@keyframes comeFromTop {
    0% { transform: translateY(-500px); }
    100% { transform: none; }
}

@keyframes comeFromBottom {
    0% { transform: translateY(500px); }
    100% { transform: none; }
}

@keyframes zoomIn {
    0% { transform: scale(0) }
    60% { transform: scale(1.1) }
    100% { transform: scale(1.0) }
}