.do-not-close {
    font-size: 14pt;
    font-weight: 500;
}

.loading-icon {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 42pt;
    color: #ff6d57;
    box-shadow: 0px 4px 6px #ff8d7c;
    border-radius: 50%;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-loading {
        width: 90%;
        padding-left: 12px;
        padding-right: 12px;
    }

    .loading-h1 {
        font-size: 18pt;
    }

    .loading-p {
        font-size: 12pt;
    }
}

/* iPhone 8 */
@media (max-width: 400px) {
    .loading-h1 {
        font-size: 17pt;
    }
}

/* iPhone SE */
@media (max-width: 330px) {
    .loading-h1 {
        font-size: 15pt;
    }

    .loading-p {
        font-size: 11pt;
    }
}