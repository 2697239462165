.privacy-policy-div {
    margin-bottom: 128px;
}
.privacy-policy-div h1 {
    text-align: center;
}

.privacy-policy-div h3 {
    margin-left: 0;
    margin-top: 16px;
    margin-bottom: 16px;
}

.privacy-policy-div p {
    padding: 3px;
}