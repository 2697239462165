.primary-button {
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 1rem;
    box-shadow: 6px 6px 1px #FF6E59;
    background: #000;
    color: #fff;
}

.primary-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.secondary-button {
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: underline;
    background: rgba(0, 0, 0, 0);
    color: #FF6E59;
}

.secondary-button:hover {
    font-weight: 700;
    text-shadow: 4px 4px 20px rgba(255, 110, 89, 0.5);
}

.link-button {
    background: none;
    border-style: none;
    font-weight: 500;
    font-size: 12pt;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
}

.modal-close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 18px;
    width: 50px;
    height: 50px;
    margin-right: 0;
    margin-left: auto;
    background-color: #000;
    transform: rotate(45deg);
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.modal-close-button:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.modal-close-button i {
    transform: rotate(-45deg);
}

.large-title {
    font-size: 52pt;
    font-weight: 700;
}

.medium-title {
    font-size: 32pt;
    font-weight: 700;
}

.small-title {
    font-size: 24pt;
    font-weight: 700;
}

.tiny-title {
    font-size: 18pt;
    font-weight: 600;
}

.subtitle {
    font-size: 16pt;
    font-weight: 500;
}

.regular-text {
    font-size: 12pt;
    font-weight: 500;
}

.footnote {
    font-size: 10pt;
    font-weight: 300;
}

.standard-form input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 11pt;
    font-family: 'Montserrat', sans-serif;
    border-radius: 12px;
    border-style: dashed;
    border-width: 1px;
    border-color: black;
    box-shadow: 0px 0px 5px #ffd1ca;
}

.standard-form input[type=checkbox] {
    width: auto;
    margin: 0;
    padding: 0;
    box-shadow: none;
    accent-color: #fa9183;
}

.horizontally-aligned-div {
    display: flex;
    align-items: center;
    gap: 32px;
}

.horizontally-aligned-div-no-gap-on-mobile {
    display: flex;
    align-items: center;
    gap: 32px;
}

.always-horizontally-aligned-div {
    display: flex;
    align-items: center;
    gap: 32px;
}

.error-message-div {
    padding: 12px 20px;
    margin-left: 24px;
    display: inline-block;
    font-weight: 500;
    font-size: 12pt;
    border-radius: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: #ff0000;
    box-shadow: 1px 2px 4px #ff0000;
}

.modal-container {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    backdrop-filter: blur(10px);
    animation: zoomIn 0.3s;
}

.modal-content-container {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 40px 40px 20px;
    border-radius: 32px;
    text-align: center;
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.loading-circle-icon {
    font-size: 32pt;
    color: #ff6d57;
    box-shadow: 0px 4px 6px #ff8d7c;
    border-radius: 50%;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}