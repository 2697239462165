.item {
  width: 275px;
  min-height: 420px;
  padding: 0 0.8rem 0.8rem 0.8rem;
  background-color: #fff;
  border-radius: 30px;
  border: 1px solid rgb(235, 235, 235);
  -webkit-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  -moz-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  text-align: center;
  flex-direction: column;
  display: flex;
  transition: 0.3s;
  margin-bottom: 100px;
}

.item-image-div {
  position: relative;
  cursor: pointer;
}

.item-image-div:hover > .item-meal-plan-expand-icon {
  right: 20px;
  padding: 14px;
  font-size: 16pt;
  border-radius: 32px; 
}

.item-image-div:hover > .item-image {
  width: 240px;
  -webkit-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
  -moz-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
  box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
}

.item-meal-plan-expand-icon {
  position: absolute;
  bottom: 14px;
  right: 24px;
  background-color: white;
  padding: 10px;
  border-radius: 24px;
  color: #e7634f;
  transition: 0.3s;
}

.item-image {
  margin-top: -2.5rem;
  width: 230px;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  border-radius: 10%;
  transition: 0.3s;
}



.item-head_desc p {
  margin: 0 0 0.5rem;
}

.item-head_desc {
  text-align: left;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 2px;
}

.head_desc-name {
  font-weight: 600;
  font-size: 1.2rem;
}

.head_desc-info {
  font-size: 0.75rem;
  color: #5a5a5a;
}

.item-calories-div {
  margin-bottom: 0.5rem;
  text-align: left;
  padding-left: 16px;
}

.price {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 30px;
  padding-left: 16px;
}

.item-add-button-div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}



.option-list-div {
  margin-left: 2px;
  margin-right: auto;
  margin-bottom: 12px;
  padding-left: 12px;
  display: flex; /* This enables flexbox */
  flex-direction: row; /* This aligns children horizontally */
  align-items: center; /* This centers items vertically within the container */
  justify-content: left; /* This centers items horizontally within the container */
}

.option-list-div p {
  padding-left: 6px;
}

.option-list-div select {
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 4px;
  /* min-width: 150px; */
  max-width: 200px;
}



.day-of-week-div {
  margin-top: 8px;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  margin-bottom: -0.8rem;
  background-color: #fcf0ed;
  color: #e7634f;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-color: #f5d1c7;
  border-width: 0.5px;
  border-style: solid;
  border-top-width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.day-of-week-div p {
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: 600;
  padding: 0.3rem;
}

.head_desc-mobile-customization {
  display: none;
  text-align: center;
  margin-bottom: 10px;
}

.head_desc-customization {
  font-size: 6pt;
  font-weight: 500;
  text-transform: uppercase;
}

.head_desc-customization-title {
  font-size: 9pt;
  font-weight: 600;
}

.tap-to-customize {
  display: none;
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .item {
    padding: 0;
    border-radius: 20px;
    margin-top: 0;
    margin-bottom: 32px;
    width: 132px;
    min-height: 225px;
  }

  .item-head_desc {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0.2rem;
  }

  .head_desc-name {
    font-size: 10pt;
    text-align: center;
  }

  .item-head_desc p {
    margin-bottom: 0.1rem;
  }

  .head_desc-info {
    visibility: hidden;
    height: 0;
  }

  .item-image {
    width: 110px;
    margin-top: -1.3rem;
  }

  .item-calories-div {
    text-align: center;
    padding-left: 0;
  }

  .item-calories-div p {
    text-align: center; /* Ensure text alignment is centered */
    width: 100%; /* Ensure the paragraph takes up the full width */
  }

  .item-calories {
    font-size: 9pt;
  }

  .item-meal-plan-expand-icon {
    margin-right: 4px;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: 10px;
    padding: 10px;
    font-size: 11pt;
  }
  
  .item-image-div:hover > .item-image {
    width: 110px;
  }
  
  .item-meal-plan-expand-icon {
    bottom: 8px;
    right: 14px;
    padding: 7px;
    font-size: 11pt;
  }

  .day-of-week-div {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    flex-direction: column;
  }
  
  .day-of-week-div p {
    font-size: 9pt;
  }

  .day-of-week-p {
    padding: 0;
  }

  .meal-consumption-type-p {
    padding: 0;
    margin-top: -8px;
  }

  .head_desc-mobile-customization {
    display: block;
  }

  .tap-to-customize {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 7pt;
    padding-top: 2px;
  }

  .desktop {
    display: none;
  } 
}

/* iPhone 12 Pro */

@media (max-width: 390px) {
  .item {
    width: 120px;
    min-height: 225px;
  }

  .item-image {
    width: 100px;
    margin-top: -1rem;
  }
}

/* iPhone SE */
@media (max-width: 375px) {
  .item {
    width: 115px;
    min-height: 220px;
  }

  .head_desc-name {
    font-size: 10pt;
  }

  .item-calories {
    font-size: 8pt;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: 6px;
  }
  
  .item-image-div:hover > .item-image {
    width: 105px;
  }

  .item-meal-plan-expand-icon {
    right: 10px;
  }

  .day-of-week-div p {
    font-size: 8pt;
  }

  .tap-to-customize {
    font-size: 6pt;
  }
}

/* iPhone SE */
@media (max-width: 320px) {
  .item {
    border-radius: 14px;
    width: 100px;
    min-height: 220px;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: -6px;
  }
  
  .item-image-div:hover > .item-image {
    width: 105px;
  }

  .item-meal-plan-expand-icon {
    right: 2px;
  }

  .day-of-week-div {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}