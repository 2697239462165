@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'styling/animations.css';
@import 'styling/main-component-style.css';
@import 'styling/secondary-component-style.css';
@import 'styling/mobile/main-mobile-component-style.css';
@import 'styling/mobile/secondary-mobile-component-style.css';

* {
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

button {
  font-family: 'Montserrat', sans-serif;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f8f8;
}

.container {
  min-width: 350px;
  max-width: 1140px;
  margin: 0 auto;
  width: 95%;
}