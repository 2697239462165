.cart-header {
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
}

.orders {
  margin: 2rem 0 0 0;
  min-height: calc(100vh - 15rem);
}

.orders-heading,
.orders-total-description-item,
.orders-total-description-meal-type,
.orders-total-description-plan,
.orders-total-description,
.orders-total {
  width: 100%;
  max-width: 600px;
}

.orders-heading {
  margin-bottom: 0;
  border-bottom: 2px solid black;
}

.orders-total {
  text-align: right;
  border-top: 2px solid #000;
  margin-left: auto;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 24pt;
}

.orders-total-description {
  text-align: right;
  border-top: 2px solid #000;
  margin-left: auto;
  margin-top: 3rem;
}

.order-description-your-total-label {
  font-size: 16pt;
}

.orders-total-description-item,
.orders-total-description-item-div,
.orders-total-description-meal-type,
.orders-total-description-plan {
  text-align: right;
  margin-left: auto;
  margin-top: 0.1rem; 
}

.orders-total-description-item-div {
  margin-bottom: 16px;
}

.orders-total-description-item-day {
  font-size: 13pt;
  font-weight: 800;
}

.orders-total-description-plan {
  font-size: 14pt;
  font-weight: 600;
  margin-bottom: 8px;
}

.orders-total-description-item {
  font-size: 11pt;
  font-weight: 400;
}

.orders-total-description-meal-type {
  font-size: 12pt;
  font-weight: 600;
}

.orders-total-description-item-option {
  color: #f14e34;
  font-weight: 500;
}

.order-description-item-price {
  padding-left: 10px;
}

.order-description-delivery-price {
  padding-left: 20px;
}

.orders-delivery-date {
  padding-left: 10px;
  padding-top: 16px;
  font-size: 16pt;
  font-weight: 400;
}

.orders-delivery-date-desc {
  padding-left: 10px;
}

.dev-email {
  color: white;
  font-weight: 700;
  text-decoration: underline;
}
 

.back-button {
  background: #000;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: 0.3s;
  box-shadow: 6px 6px 1px #FF6E59;
}

.back-button:hover {
  background: #FF6E59;
  box-shadow: 6px 6px 1px #000;
}



.clear-cart-button {
  background: #000;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: 0.3s;
  box-shadow: 6px 6px 1px #f14e34;
}

.clear-cart-button:hover {
  background: #f14e34;
  box-shadow: 6px 6px 1px #000;
}