.btnCartCount {
  width: 50px;
  height: 50px;
  background: #000;
  color: #fff;
  border-radius: 15px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 1.5rem;
  cursor: pointer;
  right: 1rem;
  transition: 0.3s;
  box-shadow: 5px 5px 1px #FF6E59;
}

.btnCartCount:hover {
  background: #FF6E59;
  box-shadow: 5px 5px 1px #000;
}

.btnCartCount .count {
  position: absolute;
  background: #FF6E59;
  bottom: -8px;
  left: -9px;
  transform: rotate(-45deg);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 9pt;
}
.btnCartCount i {
  transform: rotate(-45deg);
}
