h1 {
    margin-left: 10px;
    margin-top: 100px;
    margin-bottom: 50px;
}

.user-payment-form {
    margin-top: 32px;
    margin-bottom: 20px;
}

.user-payment-form input[type=text], .user-payment-form select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 11pt;
    font-family: 'Montserrat', sans-serif;
    border-radius: 12px;
    border-style: dashed;
	border-width: 1px;
	border-color: black;
	box-shadow: 0px 0px 5px #ffd1ca;
}

.user-payment-form-customer-details,
.user-payment-form-email,
.user-payment-form-address {
    display: flex;
    gap: 10px;
}

.user-payment-form-address-street-name {
    flex: 3.8 1 0;
}

.user-payment-form-address-street-number {
    flex: 1 1 0;
}

.user-payment-form-address-post-code {
    flex: 1 1 0;
}

.user-payment-form-address-city {
    flex: 1 1 0;
    background-color: rgb(246, 241, 232);
}

.user-payment-form-description {
    font-size: 12pt;
    padding-left: 3px;
    margin-top: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.user-payment-form-card-description {
    margin-bottom: 12px;
}

.user-payment-form-delivery-note {
    width: 100%;
    height: 100px;
    resize: none;
    font-size: 12pt;
    margin-top: 1rem;
    padding: 12px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    border-radius: 12px;
    border-style: dashed;
	border-width: 1px;
	border-color: black;
	box-shadow: 0px 0px 5px #ffd1ca;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .user-payment-form-customer-details,
    .user-payment-form-email,
    .user-payment-form-address {
        flex-direction: column;
        gap: 1px;
    }

    .user-payment-form-description {
        font-size: 11pt;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .user-payment-form-description {
        font-size: 10pt;
    }
}