.what-we-serve-div-about-us {
    text-align: center;
}

.what-we-serve-div-about-us h1 {
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 32pt;
}

.what-we-serve-images-div-about-us {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.what-we-serve-images-div-about-us div {
    width: 50%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    margin-top: 16px;
}

.what-we-serve-images-div-about-us div img {
    max-width: 100%;
    height: 200px;
    margin-bottom: 16px;
}

.what-we-serve-images-div-about-us div p {
    font-weight: 600;
    font-size: 18pt;
}

.what-we-serve-images-div-about-us .small {
    font-size: 12pt;
}



.main-div-about-us img {
    max-width: 100%;
    max-height: 400px;
}

.main-div-about-us .left-item p {
    padding-left: 84px;
}

.about-us-title-item {
    padding-bottom: 16px;
}

.main-div-about-us .right-item p {
    padding-right: 84px;
}

.main-div-about-us .right-item {
    margin-left: 16px;
}

.main-div-about-us .left-item {
    margin-right: 16px;
}

.main-div-about-us p {
    font-size: 22pt;
    line-height: 24pt;
}

.main-div-about-us .small-left, .main-div-about-us .small-right {
    font-size: 14pt;
    line-height: 20pt;
    margin-bottom: 0;
}

.main-div-about-us .small-left {
    text-align: left;
}

.main-div-about-us .small-right {
    text-align: right;
}



.delivery-info-div {
    margin-top: 64px;
    margin-bottom: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 10x;
    border-color: #FF6E59;
    border-style: solid;
    border-radius: 32px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    background-color: white;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.delivery-info {
    padding-left: 26px;
    font-size: 13pt;
    font-weight: 400;
    text-align: center;
}

.info-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 16px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #FF6E59;
}

.info-icon .fa {
    color: white;
    margin-top: 7px;
    margin-left: 12px;
}



.about-us-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}



.image-with-text {
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.text-under-image {
    display: flex;
    justify-content: space-between;
}

.text-under-image p {
    font-size: 10pt;
    color: #ef6a56;
    text-transform: uppercase;
    font-weight: 700;
}

.text-image-left {
    text-align: left;
}

.text-image-right {
    text-align: right;
}   



.mobile-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
}

.mobile-small {
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
    visibility: hidden;
    height: 0;
}

.delivery-weekend-soon {
    font-size: 11pt;
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .what-we-serve-div-about-us {
        margin-top: 64px;
    }

    .what-we-serve-images-div-about-us div {
        padding-left: 2%;
        padding-right: 2%;
    }

    .what-we-serve-images-div-about-us div p {
        font-size: 14pt;
        margin-bottom: 4px;
    }

    .what-we-serve-images-div-about-us div img {
        height: 100px;
    }

    .what-we-serve-images-div-about-us .small {
        font-size: 11pt;
    }

    .what-we-serve-div-about-us h1 {
        font-size: 24pt;
    }

    .main-div-about-us .left-item p {
        padding-left: 0px;
    }
    
    .main-div-about-us .right-item p {
        padding-right: 0px;
    }

    .main-div-about-us .right-item {
        margin-left: 3px;
    }
    
    .main-div-about-us .left-item {
        margin-right: 3px;
    }

    .main-div-about-us .small-left, .main-div-about-us .small-right {
        visibility: hidden;
        height: 0;
    }

    .mobile-small {
        visibility: visible;
        height: auto;
    }

    .main-div-about-us img {
        height: 200px;
    }

    .main-div-about-us p {
        font-size: 14pt;
        line-height: 18pt;
    }

    .text-under-image p {
        font-size: 9pt;
        line-height: 10pt;
    }

    .mobile-div {
        margin-bottom: 64px;
    }

    .about-us-item {
        margin-bottom: 64px;
    }

    .delivery-info-div {
        margin-bottom: 82px;
        width: 380px;
    }
    
    .delivery-info {
        padding-left: 32px;
        padding-right: 22px;
        font-size: 12pt;
    }
}

@media (max-width: 410px) {
    .delivery-info-div {
        width: 365px;
    }

    .delivery-info {
        padding-left: 30px;
        padding-right: 20px;
        font-size: 11pt;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .what-we-serve-images-div-about-us div p {
        font-size: 13pt;
    }

    .what-we-serve-images-div-about-us .small {
        font-size: 10pt;
    }

    .what-we-serve-images-div-about-us div img {
        height: 100px;
    }

    .what-we-serve-div-about-us h1 {
        font-size: 22pt;
    }

    .main-div-about-us img {
        height: 190px;
    }

    .main-div-about-us .right-item {
        margin-left: 0;
    }
    
    .main-div-about-us .left-item {
        margin-right: 0;
    }

    .main-div-about-us p {
        font-size: 13pt;
        line-height: 17pt;
    }

    .delivery-info-div {
        width: 340px;
        box-shadow: 4px 4px 1px #FF6E59;
    }
    
    .delivery-info {
        font-size: 10pt;
    }

    .text-under-image p {
        font-size: 8pt;
    }
}

/* iPhone SE */
@media (max-width: 320px) {
    .what-we-serve-images-div-about-us div p {
        font-size: 12pt;
    }

    .what-we-serve-images-div-about-us .small {
        font-size: 9pt;
    }

    .main-div-about-us img {
        height: 180px;
    }

    .main-div-about-us p {
        font-size: 12pt;
        line-height: 16pt;
    }

    .mobile-small {
        font-size: 10pt;
    }

    .delivery-info-div {
        width: 290px;
    }
    
    .delivery-info {
        font-size: 8pt;
    }
}