.menu-main {
  padding-top: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-row-gap: 5rem;
  animation: rollout 0.4s;
}

@keyframes rollout {
  0% { transform: translateY(500px); }
  100% { transform: none; }
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .menu-main {
    padding-top: 3rem;
    grid-row-gap: 2.5rem;
  }
}