.modal-double-item-content {
    padding-left: 60px;
    padding-right: 60px;
    width: 700px;
}

.double-item-buttons-div {
    margin-top: 12px;
    margin-bottom: 6px;
}

.double-item-yes-button {
    width: 235px;
}

.double-item-no-button {
    width: 265px;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .modal-double-item-content {
        padding-left: 40px;
        padding-right: 40px;
        width: 90%;
    }

    .double-item-p {
        margin-top: 16px;
        font-size: 11pt;
    }

    .double-item-yes-button {
        font-size: 13pt;
        width: 250px;
    }
    
    .double-item-no-button {
        font-size: 13pt;
        width: 250px;
    }
}

/* iPhone SE */
@media (max-width: 320px) {
    .modal-double-item-content {
        padding-left: 30px;
        padding-right: 30px;
        width: 95%;
    }

    .double-item-h1 {
        margin-right: 50px;
    }
}