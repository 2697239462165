/* The Modal (background) */
.modal-are-you-sure {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    backdrop-filter: blur(10px);
    animation: zoomIn 0.3s;
}

@keyframes zoomIn {
    0% { transform: scale(0) }
    60% { transform: scale(1.1) }
    100% { transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content-are-you-sure {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-radius: 32px;
    text-align: center;
    width: 600px; /* Could be more or less, depending on screen size */
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.are-you-sure-h1 {
    padding: 0;
    margin: 0;
    padding-bottom: 6px;
}

.are-you-sure-buttons-div {
    display: flex;
    justify-content: space-evenly;
}

.are-you-sure-yes-button,
.are-you-sure-no-button {
    margin-top: 16px;
	font-size: 18px;
    width: 180px;
	height: 50px;
	background-color: #000;
    border-radius: 26px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.button-icon {
    padding-right: 4px;
}

.are-you-sure-yes-button {
    box-shadow: 4px 4px 1px #f14e34;
}

.are-you-sure-yes-button:hover,
.are-you-sure-no-button:hover {
	background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.are-you-sure-yes-button:hover {
    background-color: #f14e34;
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-are-you-sure {
        width: 80%;
    }

    .are-you-sure-buttons-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .are-you-sure-h1 {
        font-size: 20pt;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .are-you-sure-h1 {
        font-size: 18pt;
    }
}