.item-meal-plan {
    width: 375px;
    padding: 0 0.8rem 0.8rem 0.8rem;
    background-color: #fff;
    border-radius: 30px;
    margin-right: 16px;
    text-align: center;
    flex-direction: column;
    display: flex;
    box-shadow: 6px 6px 1px #FF6E59;
    border-color: #FF6E59;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 16px;
}

.item-meal-plan img {
    margin-top: -3.5rem;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    border-radius: 10%;
}

.meal-plan-name {
    font-size: 20pt;
    font-weight: 600;
}

.meal-plan-info {
    font-size: 12pt;
}

.meal-plan-button {
    background: #000;
    color: #fff;
    width: 146px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 1rem;
    transition: 0.3s;
    box-shadow: 6px 6px 1px #FF6E59;
    margin-left: auto;
    margin-right: 0;
    font-weight: 400;
}

.meal-plan-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.item-meal-plan-price {
    font-size: 16pt;
    font-weight: 600;
    padding-left: 16px;
}

  /* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .meal-plans-main {
      grid-template-columns: 1fr;
    }

    .item-meal-plan {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .item-meal-plan img {
        width: 260px;
    }

    .meal-plan-name {
        font-size: 19pt;
    }

    .item-meal-plan-price {
        padding-left: 6px;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .item-meal-plan {
        width: 280px;
    }

    .item-meal-plan img {
        width: 240px;
    }

    .meal-plan-name {
        font-size: 18pt;
    }

    .item-meal-plan-price {
        padding-left: 4px;
    }
}

/* iPhone SE */
@media (max-width: 320px) {
    .item-meal-plan {
        width: 260px;
    }

    .item-meal-plan img {
        width: 220px;
    }

    .meal-plan-name {
        font-size: 16pt;
    }

    .item-meal-plan-price {
        padding-left: 2px;
    }
}  