/* The Modal (background) */
.modal {
    /* display: none; Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(255, 255, 255);
    /* Fallback color */
    background-color: rgba(255, 255, 255, 0.18);
    /* Black w/ opacity */
    backdrop-filter: blur(3px);
    animation: zoomIn 0.3s;
}

@keyframes zoomIn {
    0% {
        transform: scale(0)
    }

    60% {
        transform: scale(1.1)
    }

    100% {
        transform: scale(1.0)
    }
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 6% auto;
    /* 15% from the top and centered */
    padding: 20px;
    width: 55%;
    /* Could be more or less, depending on screen size */
    border-radius: 32px;
    /* border: 0.5px solid #FF6E59; */
    /* box-shadow: 6px 6px 1px #FF6E59; */
    -webkit-box-shadow: 6px 6px 16px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 6px 6px 16px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 6px 6px 16px 6px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: column;
}

.menu-item-close-button {
    position: absolute;
    right: 20px;
    top: 16px;
    font-size: 18px;
    width: 110px;
    height: 50px;
    margin-right: 0;
    margin-left: auto;
    background-color: #000;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.menu-item-close-button:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.menu-pop-up-img {
    width: 600px;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin-top: -4rem;
    margin-left: 16px;
    margin-right: auto;
    border-radius: 20px;
}

.meal-name {
    padding: 0;
    margin: 16px 0 0;
}

.meal-name,
.meal-info,
.pop-up-item-calories {
    padding: 6px 6px 6px 18px;
}

.option-list-div-details {
    margin: 12px auto 12px 2px;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.option-list-div-details p {
    padding-left: 6px;
}

.option-list-div-details select {
    font-family: 'Montserrat', sans-serif;
    font-size: 12pt;
    font-weight: 500;
    border-radius: 12px;
    padding: 4px;
    /* min-width: 150px; */
    /*max-width: 220px;*/
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .modal-content {
        margin: 30% auto;
        width: 90%;
    }

    .menu-pop-up-img {
        margin-top: -15%;
        margin-left: auto;
        width: 100%;
    }

    .meal-name,
    .meal-info,
    .pop-up-item-calories {
        padding: 6px;
    }

    .menu-item-close-button {
        position: fixed;
        right: 40px;
    }

    .option-list-div-details {
        padding-left: 2px;
    }

    .option-list-div-details select {
        max-width: 90%;
    }
}