.floating {
    position: fixed;
}

.centered-text {
    text-align: center;
}

.mobile-only {
    visibility: hidden;
    height: 0;
}

.tablet-only {
    visibility: hidden;
    height: 0;
}

.desktop-only {
    visibility: visible;
    height: auto;
}

.fifty-wide {
    width: 50%;
}

.sixty-wide {
    width: 60%;
}

.seventy-wide {
    width: 70%;
}

.eighty-wide {
    width: 80%;
}

.large-margin-top {
    margin-top: 48px;
}

.large-margin-bottom {
    margin-bottom: 48px;
}

.large-margin-left {
    margin-left: 48px;
}

.large-margin-right {
    margin-right: 48px;
}

.standard-margin-top {
    margin-top: 24px;
}

.standard-margin-bottom {
    margin-bottom: 24px;
}

.standard-margin-left {
    margin-left: 24px;
}

.standard-margin-right {
    margin-right: 24px;
}

.small-margin-top {
    margin-top: 12px;
}

.small-margin-bottom {
    margin-bottom: 12px;
}

.small-margin-left {
    margin-left: 12px;
}

.small-margin-right {
    margin-right: 12px;
}

.small-padding-left {
    padding-left: 8px;
}

.small-padding-right {
    padding-right: 8px;
}

.color-mokka {
    color: #FF6E59;
}

.background-color-mokka {
    background: #FF6E59;
}