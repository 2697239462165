/* Tablet */
@media (max-width: 1024px) {
    .tablet-only {
        visibility: visible;
        height: auto;
    }

    .desktop-only {
        visibility: hidden;
        transition: 0s;
        height: 0;
        width: 0;
    }

    .fifty-wide {
        width: 75%;
    }

    .sixty-wide {
        width: 80%;
    }

    .seventy-wide {
        width: 85%;
    }

    .eighty-wide {
        width: 90%;
    }
}

/* Largest Mobile */
@media (max-width: 499px) {
    .mobile-only {
        visibility: visible;
        height: auto;
    }

    .desktop-only {
        visibility: hidden;
        height: 0;
    }

    .fifty-wide {
        width: 97%;
    }

    .sixty-wide {
        width: 97%;
    }

    .seventy-wide {
        width: 97%;
    }

    .eighty-wide {
        width: 97%;
    }
}

/* iPhone 14 Pro Max up to Largest Mobile */
@media (max-width: 430px) {

}

/* iPhone 12 Pro up to iPhone 14 Pro Max */
@media (max-width: 390px) {

}

/* iPhone SE up to iPhone 12 Pro */
@media (max-width: 375px) {

}