header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

header div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header div > * {
  margin: 8px;
}

header img {
  width: 550px;
  align-self: center;
}

/* Tablet */
@media (max-width: 1024px) {
  header {
    grid-template-columns: auto;
    margin-top: 10vh;
  }

  header img {
    min-width: 400px;
    max-width: 60%;
  }

  header div > * {
    align-self: center;
    text-align: center;
  }
}

/* Largest Mobile */
@media (max-width: 500px) {
  header img {
    min-width: 40%;
    max-width: 90%;
  }
}