.FormGroup {
	border-radius: 12px;
	padding: 6px;
	border-style: dashed;
	border-width: 1px;
	border-color: black;
	background-color: white;
	box-shadow: 0px 0px 5px #ffd1ca;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

.payment-button {
	background: #000;
	color: #fff;
	width: 353px;
	padding: 1rem 1.5rem;
	display: flex;
	outline: none;
	border: none;
	font-size: 1rem;
	border-radius: 25px;
	cursor: pointer;
	margin-top: 1.5rem;
	transition: 0.3s;
	box-shadow: 6px 6px 1px #FF6E59;
	justify-content: space-between;
	margin-left: auto;
	margin-right: 0;
	font-weight: 500;
}

.payment-button:hover {
	background-color: #FF6E59;
	box-shadow: 6px 6px 1px #000;
}

h3 {
	margin-left: 15px;
	margin-top: 50px;
}

.stripe-link {
	color: black;
	text-decoration: none;
}

.stripe-div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 9pt;
	font-weight: 500;
	margin-top: 8px;
}

.stripe-logo {
	transform: scale(2.2, 2.2);
	padding-left: 10px;
	padding-right: 10px;
	/* font-size: 20pt; */
	/* margin-top: 8px; */
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .payment-button {
		margin-right: auto;
	}
}