@keyframes greenGlow {
    0% {
        box-shadow: 0 0 0 0 rgb(68, 214, 78);
    }
    50% {
        box-shadow: 0 0 0 5px rgb(86, 238, 108);
    }
    80% {
        box-shadow: 0 0 100px 20px rgb(94, 248, 120);
    }
    100% {
        box-shadow: 0 0 300px 0 rgb(108, 252, 134);
    }
}

.image-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 16px;
}

.image-container img {
    width: 55px;
    height: 55px;
}

.image-container p {
    background: rgba(51, 192, 49, 0.5);
    backdrop-filter: blur(10px);
    padding: 8px 16px;
    border-radius: 16px;
    font-size: 9pt;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
}

.image-container img.animated-glow {
    animation: greenGlow 4s infinite ease-in-out;
    border-radius: 16px; /* Optional: Matches the image style */
}

/* Largest Mobile */
@media (max-width: 500px) {
    .image-container img {
        width: 45px;
        height: 45px;
    }
}