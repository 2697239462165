.meal-plans-div {
  margin-top: 20px;
  margin-bottom: 48px;
  padding-top: 3.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: stretch;
  grid-row-gap: 5rem;
  scroll-margin: 160px;
}

.meal-plans-desc {
  text-align: center;
  font-size: 18pt;
  margin-bottom: 32px; 
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .meal-plans-div {
    grid-template-columns: 1fr;
  }

  .meal-plans-desc {
    font-size: 16pt;
  }
}