.mokka-footer {
  border-top: 1px solid #000;
  margin-top: 3rem;
  padding: 2rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer div p {
  font-weight: 500;
  max-width: 600px;
  margin-right: 16px;
}



.credits-footer {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 32px;
  text-align: right;
  float: right;
}

.credits-footer a, .button-link {
  color: black;
  font-weight: 500;
  font-size: 13pt;
  text-decoration: underline;
  padding-top: 10px;
  padding-bottom: 6px;
}

.button-link {
  padding: 0;
  padding-bottom: 6px;
  margin: 0;
  border-style: none;
  background: none;
  text-align: right;
}

.credits-footer a:hover, .button-link {
  cursor: pointer;
}



.recyclable-div {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.recyclable-div p {
  font-size: 16pt;
  font-weight: 500;
}

.recyclable-div img {
  width: 35px;
  height: auto;
  margin-left: 4px;
}



.item-heart {
  font-size: 1.4rem;
}

.item-thank-you {
  font-size: 1.2rem;
}


.footer-version {
  position: absolute;
  font-size: 10pt;
  color: lightgray;
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .mokka-footer p {
    font-size: 0.9rem;
  }

  .item-thank-you {
    font-size: 1.1rem;
  }

  .item-heart {
    font-size: 1.1rem;
  }

  .recyclable-div {
    margin-top: 24px;
  }

  .recyclable-div p {
    font-size: 12pt;
  }

  .recyclable-div img {
    width: 25px;
    margin-left: 0;
  }

  .credits-footer a, .button-link {
    font-size: 10pt;
  }
}

/* iPhone 8 */
@media (max-width: 375px) {
  .recyclable-div p {
    font-size: 11pt;
  }

  .credits-footer a, .button-link {
    font-size: 9pt;
  }
}

/* iPhone SE */
@media (max-width: 320px) {
  .recyclable-div p {
    font-size: 10pt;
  }
}