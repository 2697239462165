/* Tablet */
@media (max-width: 1024px) {

}

/* Largest Mobile */
@media (max-width: 499px) {
    .primary-button {
        font-size: 0.9rem;
        box-shadow: 5px 5px 1px #FF6E59;
    }

    .secondary-button {
        font-size: 0.9rem;
        padding: 8px;
    }

    .large-title {
        font-size: 38pt;
    }

    .medium-title {
        font-size: 28pt;
    }

    .small-title {
        font-size: 20pt;
    }

    .subtitle {
        font-size: 13pt;
    }

    .horizontally-aligned-div {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .horizontally-aligned-div-no-gap-on-mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
    }
}

/* iPhone 14 Pro Max up to Largest Mobile */
@media (max-width: 430px) {

}

/* iPhone 12 Pro up to iPhone 14 Pro Max */
@media (max-width: 390px) {
    .primary-button, .secondary-button {
        font-size: 0.85rem;
    }
}

/* iPhone SE up to iPhone 12 Pro */
@media (max-width: 375px) {
    .primary-button, .secondary-button {
        font-size: 0.8rem;
    }
}